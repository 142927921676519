import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(private http: HttpClient) {}

  subscribe(mail: string) {
    return this.http.post(`${environment.API_URL}newsletter/subscribe`, {mail});
  }

  unsubscribe(id: string) {
    return this.http.put(`${environment.API_URL}newsletter/unsubscribe/${id}`, {});
  }
}
