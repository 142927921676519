<section id="pricing" class="bg-light">
  <div class="container">
    <div class="section-title">
      <h2>Tarifs</h2>
      <p>L'offre qui vous convient le mieux</p>
    </div>

    <div class="row g-5">
      <div class="col-lg-4 col-md-12 d-flex align-items-stretch mb-sm-2">
        <div class="card price-card free shadow">
          <h2 class="name">Gratuit</h2>
          <div class="features-list">
            <ul>
              <li>
                <i class="ri-check-double-line"></i>
                <p>Apparaitre dans les pages de recherches.</p>
              </li>
              <li>
                <i class="bi bi-cart-plus"></i>
                <p>Récéption des leads (Facturation à l'unité)</p>
              </li>
              <li>
                <i class="bi bi-cart-plus"></i>
                <p>Publication d'articles (Facturation à l'unité)</p>
              </li>
              <li>
                <i class="bi bi-cart-plus"></i>
                <p>Nouvelles fonctionnalités (Facturation à l'unité)</p>
              </li>

            </ul>
          </div>
          <div class="price mt-2">
            <a routerLink="/inscription" class="btn btn-light">Démarrer l'aventure dès maintenant</a>
          </div>
        </div>
      </div>

      <div class="col-lg-8 col-md-12 d-flex align-items-stretch mb-sm-2">
        <div class="card price-card secondary shadow">
          <h3 class="name">Premium</h3>
          <div class="features-list">
            <ul>
              <li>
                <i class="ri-check-double-line"></i>
                <p>Apparaitre dans les pages de recherches.</p>
              </li>
              <li>
                <i class="ri-check-double-line"></i>
                <p>Récéption des leads</p>
              </li>
              <li>
                <i class="ri-check-double-line"></i>
                <p>Publication d'articles</p>
              </li>
              <li>
                <i class="ri-check-double-line"></i>
                <p>Garantie première page</p>
              </li>
              <li>
                <i class="ri-check-double-line"></i>
                <p>Nouvelles fonctionnalités sans surcoût</p>
              </li>
            </ul>
          </div>
          <div class="price text-center mt-2">
              <a routerLink="/incription" class="btn btn-primary">Démarrer l'aventure dès maintenant</a>
              <a routerLink="/contact" class="btn btn-outline-primary">Demander un devis personnalisé</a>
            </div>

          </div>
        </div>
      </div>

    </div>
</section>
