<section id="origine" class="bg-light">
	<div class="container">
		<div class="section-title">
			<h2>Histoire</h2>
			<p>Découvrez comment tout a commencé !</p>
		</div>
		<div class="timeline timeline-one">
			<!-- Timeline Item 1 -->
			<div class="timeline-item">
			   <span class="icon icon-info icon-lg"><i class="fab fa-react"></i></span>
			   <h4>Origine</h4>
			   Après une expérience mitigée dans un cabinet de courtage, 
			   j’ai pu observer combien il était difficile pour les courtiers d’obtenir de nouveaux clients.<br>
			   N'oublions pas non plus ces derniers qui sont tout aussi perdus avec les pubs qu'ils reçoivent à longueur de journée.			</div>
			<!-- Timeline Item 2 -->
			<div class="timeline-item">
			   <span class="icon icon-secondary"><i class="fab fa-vuejs"></i></span>
			   <h4 class="my-3">Concept</h4>
			   <p>En discutant entre nous du problème, nous avons imaginé une solution où la majorité des courtiers serait réunie au même endroit. Puis les demandeurs iraient directement chercher le courtier dont ils ont besoin.</p>
			</div>
			<!-- Timeline Item 3 -->
			<div class="timeline-item">
			   <span class="icon icon-danger"><i class="fab fa-angular"></i></span>
			   <h4 class="my-3">Motivation</h4>
			   <p>Le milieu du courtage est très conccurentiel. Tellement, qu’il peut en devenir malsain !Nous avons donc opté pour une solution juste et neutre où chacun peut échanger en toute sécurité.</p>
			</div>
		 </div>
	</div>
</section>