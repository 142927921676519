<section
  id="home"
  class="d-flex align-items-center justify-content-center mt-5"
>
  <div class="container d-flex align-items-center justify-content-center">
    <div class="row justify-content-md-center">
      <div
        id="text-col"
        class="col-sm-12 col-md-5 d-flex flex-column justify-content-center"
      >
        <h1>Mon Courtier & Moi</h1>
        <h2>
          En quête de nouveaux prospects ?
          Améliorez votre visibilité et touchez plus de personnes.
        </h2>
        <div id="search-link" class="mt-2 col-sm-12 col-md-7">
          <a
            class="btn btn-primary"
            type="button"
            id="button-addon2"
            routerLink="/enrollment"
          >
            Je démarre gratuitement
            <i class="bi bi-chevron-double-right"></i>
          </a>
            <div class="input-group mb-3">
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-7 img-col">
        <img
          id="home-img"
          class="img-fluid"
          src="/assets/img/help.png"
          alt="Help picture"
        />
      </div>
    </div>
  </div>
</section>
