<section id="whyus" class="bg-light">
	<div class="container">
	  <div class="section-title">
		<h2>Pourquoi ?</h2>
		<p>Vous offrir le meilleur est notre priorité</p>
	  </div>
	
	  <div class="row">
		<div class="col-lg-6 col-md-12 d-flex align-items-center order-2 order-lg-1">
		  <div class="icons">
			<div class="icon-item mb-2">
			  <div class="icon-box bg-primary p-4">
				<i class="bi bi-shield-check fs-1"></i>
			  </div>
			  <div class="icon-text mb-2">
				<h4 class="fw-bolder">Souverainté</h4>
				<p class="text-muted fw-semibold">
					Garder <span class="text-primary">un contrôle total</span> sur vos <span class="text-primary">données hébergés en France</span>.</p>
			  </div>
			</div>
			<div class="icon-item">
			  <div class="icon-box bg-secondary p-4">
				<i class="bi bi-check2-square text-white"></i>
			  </div>
			  <div class="icon-text">
				<h4 class="fw-bolder">Equité</h4>
				<p class="text-muted fw-semibold">
					Nous tenons à <span class="text-primary">être juste</span>
					dans <span class="text-primary">nos décisions</span> et dans <span class="text-primary">nos algorithmes</span>.</p>
			  </div>
			</div>
			<div class="icon-item">
			  <div class="icon-item">
				<div class="icon-box bg-info p-4">
				  <i class="bi bi-emoji-sunglasses text-white"></i>
				</div>
				<div class="icon-text">
				  <h4 class="fw-bolder">Transparence</h4>
				  <p class="text-muted fw-semibold"><span class="text-primary">Accédez aux informations</span> dont vous avez besoin, elles sont <span class="text-primary">à votre disposition</span>.</p>
				</div>
			  </div>
			</div>
		  </div>
		</div>
		<div class="col-lg-6 col-md-12 order-1 order-lg-2">
		  <img src="/assets/img/valeurs.png" alt="Illustration: Pourquoi choisir Mon Courtier & Moi ?" class="img-fluid">
		</div>
	  </div>
	</div>
  </section>
  