<section
  id="search"
  class="d-flex align-items-center justify-content-center"
>
  <div class="container">
    <div class="section-title">
      <h2>Visibilité</h2>
      <p>Le moteur de recherche</p>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 img-col">
        <img
          id="search-img"
          class="img-fluid"
          src="/assets/img/search.svg"
          alt="Search picture"
        />
      </div>
      <div class="col-sm-12 col-md-6 d-flex flex-column justify-content-center">
        <div class="search-items">
          <div class="search-item">
            <div class="item-title fs-3">
              <i class="bi bi-check2-all text-primary"></i>
              Plusieurs paramètres de recherche
            </div>
            <div class="item-detail">
              <ul>
                <li>Le nom commercial de votre entreprise</li>
                <li>Les mots clés qui correspondent à votre activité</li>
                <li>Votre localisation (rue, ville, département, région)</li>
                <li>Votre catégorie ORIAS</li>
              </ul>
            </div>
          </div>

          <div class="search-item">
            <div class="item-title fs-3">
              <i class="bi bi-check2-all text-primary"></i>
              2 modes de recherches
            </div>
            <div class="item-detail">
              <ul>
                <li>
                  Recherche <strong>précise</strong> effectuée par nom exact
                </li>
                <li>
                  Recherche <strong>élargie</strong> effectuée par expressions
                  communes
                </li>
              </ul>
            </div>
          </div>

          <div class="search-item">
            <div class="item-title fs-3">
              <i class="bi bi-check2-all text-primary"></i>
              Ordonnancement aléatoire
            </div>
            <div class="item-detail">
              <ul>
                <li>

                  Pour garantir la pertinence et la diversité des résultats,
                  l'ordre d'apparition des résultats est calculé de façon aléatoire à chaque session.
                </li>
                <li>
                  L'ordre est conservé pendant la navigation sur une recherche.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
