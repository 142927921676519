import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'CurvedCtaFeatures',
  templateUrl: './curved-cta.component.html',
  styleUrls: ['./curved-cta.component.scss']
})
export class CurvedCtaFeaturesComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToElementSearch() {
    this.router.navigate(['/fonctionnalites'], { fragment: 'search' });
  }
}
