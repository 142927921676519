<div class="hexa-card">
	<div class="card-icon">
		<div class="hex">
			<ng-content select="[icon]"></ng-content>
		</div>
	</div>
	<div class="card-text">
		<ng-content select="[title]"></ng-content>
		<ng-content class="inner-text" select="[text]"></ng-content>
	</div>
</div>
