<section
  id="team"
  class="d-flex align-items-center justify-content-center team bg-light"
>
  <div class="container">
    <div class="section-title">
      <h2>L'équipe</h2>
      <p>Qui sommes-nous ?</p>
    </div>

    <div class="row">
      <div class="col-lg-4 offset-lg-2 col-md-6 d-flex align-items-stretch">
        <div class="member" data-aos="fade-up" data-aos-delay="100">
          <div class="member-img">
            <img
              src="assets/img/yaniv.jpeg"
              class="img-fluid"
              alt=""
            />
            <div class="social">
              <a href="https://www.linkedin.com/in/yaniv-guenoun-28b06414b/"><i class="bi bi-linkedin"></i></a>
            </div>
          </div>
          <div class="member-info">
            <h4>Yaniv GUENOUN</h4>
            <span>Responsable métier</span>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
        <div class="member" data-aos="fade-up" data-aos-delay="100">
          <div class="member-img">
            <img
              src="assets/img/ruben.jpeg"
              class="img-fluid"
              alt=""
            />
            <div class="social">
              <a href="https://www.linkedin.com/in/ruben-guenoun-b21a88b5/"><i class="bi bi-linkedin"></i></a>
              
            </div>
          </div>
          <div class="member-info">
            <h4>Ruben GUENOUN</h4>
            <span>Responsable technique</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
