import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'FeatureSearch',
  templateUrl: './feature-search.component.html',
  styleUrls: ['./feature-search.component.scss']
})
export class FeatureSearchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
