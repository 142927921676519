import { NewsletterModalComponent } from './../../shared/newsletter-modal/newsletter-modal.component';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'FeatureComing',
  templateUrl: './feature-coming.component.html',
  styleUrls: ['./feature-coming.component.scss']
})
export class FeatureComingComponent implements OnInit {

  @ViewChild("newsletter", {static: true}) newsletterModal!: NewsletterModalComponent;
  constructor() { }

  ngOnInit(): void {
  }

  openNewsletterModal() {
    this.newsletterModal.openModal();
  }
}
