import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToasterService } from '../services/toaster.service';

@Component({
  selector: 'Toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent {

  constructor(public toastService: ToasterService) {}

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }

}
