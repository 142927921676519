<ng-template #newsletter let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Restez informé des dernières nouveautés</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body form-newsletter">
		<div class="mb-3">
			<NewsletterForm></NewsletterForm>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="modal.close('Save click')">Fermer</button>
	</div>
</ng-template>
