<section id="cta" class="cta">
  <div class="container">
	<div class="section-title">
		<h2>Opportunité</h2>
		<p>L'aventure MC&M vous tente ?</p>
	  </div>

    <div data-aos="zoom-in">
      <div>
        <p>
          Rejoignez la plateforme et commencez à augmenter votre visibilité pour
          chercher de nouveaux clients.
        </p>
        <a class="btn btn-outline-primary" routerLink="/inscription">Je m'inscris</a>
      </div>
    </div>
  </div>
</section>
