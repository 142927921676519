<section id="services" class="services bg-light">
  <div class="container">
    <div class="section-title">
        <h2>Notre offre</h2>
        <p>Découvrez notre offre de services</p>
      </div>

	<div class="row mt-4 g-6">
		<div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
		  <div class="icon-box border-white rounded shadow">
			<div class="icon"><i class="bi bi-people"></i></div>
			<div class="row">
				<div class="col-12 d-flex align-items-center gap-3">
					<h4 class="m-0"><a href="">Nouveaux prospects</a></h4>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-12">
					<p class="text-muted fs-5">Profitez d'une visibilité liée aux critères recherchés par les
						internautes.</p>
				</div>
			</div>
		  </div>
		</div>

		<div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
		  <div class="icon-box border-white rounded shadow">
			<div class="icon"><i class="bi bi-newspaper"></i></div>
			<div class="row">
				<div class="col-12 d-flex align-items-center gap-3">
					<h4 class="m-0"><a href="">Diffusion d'informations</a></h4>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-12">
					<p class="text-muted fs-5"> Publiez des articles pour informer la communauté et attirer de
				</div>
			</div>
		  </div>
		</div>

		<div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
			<div class="icon-box border-white rounded shadow">
			  <div class="icon"><i class="bi bi-arrow-down-up"></i></div>
			  <div class="row">
				  <div class="col-12 d-flex align-items-center gap-3">
					  <h4 class="m-0"><a href="">Nouveautés</a></h4>
				  </div>
			  </div>
			  <div class="row mt-3">
				  <div class="col-12">
					  <p class="text-muted fs-5">Notre plateforme s'améliore en continu pour vous proposer des services innovants et performants. </p>
				  </div>
			  </div>
			</div>
		  </div>

	  </div>
  </div>
</section>