<section id="curved-cta navbar" class="curved-cta">
	<div class="container" data-aos="zoom-in">
	  <div class="row">
		<div class="link-list">
			<div class="col-lg-4 col-md-6 col-sm-12 link-item">
				<a [href]="'/a-propos#projet'" class="btn btn-curve btn-outline-primary btn-lg scrollto">Notre projet</a>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12 link-item">
				<a [href]="'/a-propos#team'" class="btn btn-curve btn-outline-primary btn-lg scrollto">Nous</a>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12 link-item">
				<a [href]="'/a-propos#origine'" class="btn btn-curve btn-outline-primary btn-lg scrollto">D'où l'on vient</a>
			</div>
		</div>
	  </div>
	</div>
</section>