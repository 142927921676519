<section
  id="espace"
  class="d-flex align-items-center justify-content-center services"
>
  <div class="container">
    <div class="section-title">
      <h2>Gestion</h2>
      <p>Votre espace courtier</p>
    </div>

    <div class="row mt-4 g-6">
      <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
        <div class="icon-box border-white rounded shadow">
        <div class="icon"><i class="bi bi-people"></i></div>
        <div class="row">
          <div class="col-12 d-flex align-items-center gap-3">
            <h4 class="m-0"><a href="">Gérez vos leads</a></h4>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <p class="text-muted fs-5">Suivez de près les personnes qui vous ont contacté grâce à la plateforme mais pas que...</p>
          </div>
        </div>
        </div>
      </div>
  
      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
        <div class="icon-box border-white rounded shadow">
        <div class="icon"><i class="bi bi-newspaper"></i></div>
        <div class="row">
          <div class="col-12 d-flex align-items-center gap-3">
            <h4 class="m-0"><a href="">Rédigez vos articles</a></h4>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <p class="text-muted fs-5"> De la rédaction à la publication, suivez vos articles pour vous demarquer.
          </div>
        </div>
        </div>
      </div>
  
      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
        <div class="icon-box border-white rounded shadow">
          <div class="icon"><i class="bi bi-database-fill-gear"></i></div>
          <div class="row">
            <div class="col-12 d-flex gap-3">
              <h4 class="m-0 text-start"><a href="">Contrôlez vos données personnelles</a></h4>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <p class="text-muted fs-5">Gérez vos données en toutes sécurités.</p>
            </div>
          </div>
        </div>
        </div>
  
      </div>
  </div>
</section>
