import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'AboutCta',
  templateUrl: './about-cta.component.html',
  styleUrls: ['./about-cta.component.scss']
})
export class AboutCtaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
