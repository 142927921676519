<section id="about" class="about bg-light">
  <div class="container">
    <div class="section-title">
      <h2>L'entreprise</h2>
      <p>Découvrez notre projet</p>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <img src="assets/img/mockup.jpeg" class="img-fluid" alt="" />
      </div>
      <div class="col-lg-6 pt-4 pt-lg-0 content">
        <h3>
          Faciliter les courtiers dans leurs recherches de nouveaux clients
        </h3>
        <p class="fs-4">
          Nous avons développer <span class="text-primary">plusieurs outils</span> pour 
          <span class="text-primary">vous faire gagner en visibilité</span>.
          Vous pourrez cibler des prospects à plus grande echelle.
        </p>
        <ul>
          <li>
            <i class="bi bi-check2-circle fs-3"></i>
            <h4>Un moteur de recherche</h4>
            Laissez la possibilité aux utilisateurs de vous contacter pour répondre à leur besoin
          </li>
          <li>
            <i class="bi bi-check2-circle fs-3"></i>
            <h4>Un espace courtier</h4>
            Gerez vos demandes de contact depuis un seul endroit.
          </li>
          <li>
            <i class="bi bi-check2-circle fs-3"></i>
            <h4>Un blog</h4>
            Informez votre communauté de l’actualité mais aussi de nouveaux internautes 
            et profitez d’un booster de communication.
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>