<section id="curved-cta navbar" class="curved-cta">
	<div class="container" data-aos="zoom-in">
	  <div class="row">
		<div class="link-list">
			<div class="col-lg-3 col-md-6 col-sm-12 link-item">
				<a [href]="'/fonctionnalites#search'" class="btn btn-curve btn-outline-primary btn-lg scrollto">Le moteur de recherche</a>
			</div>
			<div class="col-lg-3 col-md-6 col-sm-12 link-item">
				<a [href]="'/fonctionnalites#espace'" class="btn btn-curve btn-outline-primary btn-lg scrollto">Votre espace courtier</a>
			</div>
			<div class="col-lg-3 col-md-6 col-sm-12 link-item">
				<a [href]="'/fonctionnalites#news'" class="btn btn-curve btn-outline-primary btn-lg scrollto">Les prochaines nouveautés</a>
			</div>
			<div class="col-lg-3 col-md-6 col-sm-12 link-item">
				<a [href]="'/fonctionnalites#articles'" class="btn btn-curve btn-outline-primary btn-lg scrollto">La publication d'article</a>
			</div>
		</div>
	  </div>
	</div>
</section>