import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { ToasterService } from '../shared/services/toaster.service';

interface Contact {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  message: string;
}

@Component({
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

  firstName: UntypedFormControl;
  lastName: UntypedFormControl;
  email: UntypedFormControl;
  subject: UntypedFormControl;
  message: UntypedFormControl;
  contactForm: UntypedFormGroup;

  constructor(
    public toastsService: ToasterService,
    private fb: UntypedFormBuilder,
    private http: HttpClient
  ) {
    this.firstName = this.fb.control('', [Validators.required]);
    this.lastName = this.fb.control('', [Validators.required]);
    this.email = this.fb.control('', [Validators.required, Validators.email]);
    this.subject = this.fb.control('', [Validators.required]);
    this.message = this.fb.control('', [Validators.required]);
    this.contactForm = fb.group({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      subject: this.subject,
      message: this.message,
    });
    console.log(environment.API_URL)
  }

  ngOnInit(): void {
  }

  sendContact() {
    if (this.contactForm.valid) {
      const contact: Contact = {
        firstName: this.firstName.value,
        lastName: this.lastName.value,
        email: this.email.value,
        subject: this.subject.value,
        message: this.message.value,
      };
      this.toastsService.show('Envoi en cours ...');
      this.http.post(environment.API_URL + 'contact', contact).subscribe({
        next: () => {
          this.contactForm.reset();
          this.toastsService.showSuccess('Message envoyé !');
        },
        error: (error) => {
          console.error(error)
          this.toastsService.showError(
            "Une erreur est survenue pendant l'envoi de votre message. Merci de réessayer"
          );
        },
      });
    }
  }
}
