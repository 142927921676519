import { SharedRoutingModule } from './shared-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToasterComponent } from './toaster/toaster.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { NavBarHomeComponent } from './nav-bar-home/nav-bar-home.component';
import { FooterComponent } from './footer/footer.component';
import { ValidatorsService } from './services/validators.service';
import { HexaCardComponent } from './hexa-card/hexa-card.component';
import { NewsletterModalComponent } from './newsletter-modal/newsletter-modal.component';
import { NewsletterFormComponent } from './newsletter-form/newsletter-form.component';


@NgModule({
  declarations: [
    ToasterComponent,
    NavBarHomeComponent,
    FooterComponent,
    HexaCardComponent,
    NewsletterModalComponent,
    NewsletterFormComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedRoutingModule
  ],
  exports: [
    ToasterComponent,
    NavBarHomeComponent,
    FooterComponent,
    HexaCardComponent,
    NewsletterModalComponent
  ],
  providers: [ValidatorsService]
})
export class SharedModule { }
