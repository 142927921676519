<section
  id="home"
  class="d-flex align-items-center justify-content-center mt-lg-5"
>
  <div class="container d-flex justify-content-center flex-column">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-sm-12">

        <img
        class="img-fluid"
        id="home-img"
        src="/assets/img/services.svg"
        alt="Help picture"
        />
      </div>
    </div>

    <div class="row justify-content-md-center">
      <div
        id="text-col"
        class="col-sm-12 d-flex flex-column justify-content-center text-center"
      >
        <h1>Découvrez les fonctionnalités</h1>
        <h2>
          Tirez un maximum d'avantages de Mon Courtier & Moi
		  en découvrant ce que nous changeons dans votre quotidien.
        </h2>
        <div id="search-link" class="mt-2 col-sm-12 text-center">
          <a [routerLink]="['/fonctionnalites']" fragment="search" class="text-center fs-1">
            <i class="bi bi-chevron-double-down"></i>
          </a>
        </div>
      </div>
      <div class="col-sm-12 col-md-7 img-col">
      
      </div>
    </div>
  </div>
</section>
