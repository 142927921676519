import { AboutPageComponent } from './about-page/about-page.component';
import { FeaturesPageComponent } from './features-page/features-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { HomePageComponent } from './home-page/home-page.component';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    title:
      "Mon Courtier et Moi - Pour les pros - Trouver de nouveaux prospects",
  },
  {
    path: "contact",
    component: ContactPageComponent,
    title: "Contactez-nous - Mon Courtier et Moi"
  },
  {
    path: "fonctionnalites",
    component: FeaturesPageComponent,
    title: "Découvrez les fonctionnalités de l'application - Mon Courtier et Moi"
  },
  {
    path: "a-propos",
    component: AboutPageComponent,
    title: "À propos de l'entreprise - Mon Courtier et Moi"
  },
  { path: 'inscription', loadChildren: () => import('./enrollment/enrollment.module').then(m => m.EnrollmentModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
