<NavBarHome></NavBarHome>
<main>
  <section id="contact" class="contact mt-5">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Contact</h2>
        <p>Contactez-nous</p>
      </div>

      <!-- <div>
		<iframe style="border:0; width: 100%; height: 270px;" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" allowfullscreen></iframe>
	  </div> -->

      <div class="row mt-5">
        <div class="col-lg-4">
          <div class="info">
            <!-- <div class="address">
			  <i class="bi bi-geo-alt"></i>
			  <h4>Location:</h4>
			  <p>A108 Adam Street, New York, NY 535022</p>
			</div> -->

            <div class="email">
              <i class="bi bi-envelope"></i>
              <h4>Email:</h4>
              <p>contact@moncourtieretmoi.com</p>
            </div>

            <div class="phone">
              <i class="bi bi-phone"></i>
              <h4>Téléphone:</h4>
              <p>+33 1 23 45 67 89</p>
            </div>
          </div>
        </div>

        <div class="col-lg-8 mt-5 mt-lg-0">
          <form
            [formGroup]="contactForm"
            (ngSubmit)="sendContact()"
            class="php-email-form"
          >
            <div class="row">
              <div class="col-md-6 form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nom"
                  formControlName="lastName"
                  name="lastName"
                  id="lastName"
                  required
                />
              </div>
              <div class="col-md-6 form-group mt-3 mt-md-0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Prénom"
                  formControlName="firstName"
                  name="firstName"
                  id="firstName"
                  required
                />
              </div>
            </div>
            <div class="form-group mt-3">
              <input
                type="email"
                class="form-control"
                placeholder="Adresse mail"
                formControlName="email"
                name="email"
                id="email"
                required
              />
            </div>
            <div class="form-group mt-3">
              <input
                type="text"
                class="form-control"
                placeholder="Objet"
                formControlName="subject"
                name="subject"
                id="subject"
                required
              />
            </div>
            <div class="form-group mt-3">
              <textarea
                class="form-control"
                placeholder="Message"
                formControlName="message"
                name="message"
                rows="5"
                required
              ></textarea>
            </div>
            <div class="text-center">
              <button
                class="btn btn-primary mt-3"
                type="submit"
                [disabled]="contactForm.invalid"
              >
                Envoyer le message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- End Contact Section -->
</main>

<Footer></Footer>
