<section id="articles" class="d-flex align-items-center justify-content-center">
  <div class="container">
    <div class="section-title">
      <h2>Communication</h2>
      <p>l'espace article</p>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 d-flex flex-column order-2 order-lg-1">
        <div class="search-items">
          <div class="search-item">
            <div class="item-title fs-3">
              <i class="bi bi-check2-all text-primary"></i>
              Écrivez et publiez facilement en 3 étapes
            </div>
            <div class="item-detail">
              <p>Rédigez l'article sur le thème de votre choix</p>
              <p class="fst-italic">
                La publication d'articles est soumise à une relecture par nos
                modérateurs. Les délais de publication peuvent être allongés.
              </p>
            </div>
          </div>

          <div class="search-item">
            <div class="item-title fs-3">
              <i class="bi bi-pencil text-primary"></i>
              Modifiez en quelques clics vos contenus
            </div>
            <div class="item-detail">
              <ul>
                <li>Partager l'information le plus fidèlement possible</li>
                <li>Apporter rapidement des corrections à vos contenus</li>
                <li>Mise à jour immédiate</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-sm-10 col-md-6 order-1 order-lg-2 d-flex justify-content-center"
      >
        <img
          id="article-img"
          class="img-fluid"
          src="/assets/img/article.png"
          alt="Photo d'illustration pour l'espace courtier"
        />
      </div>
    </div>
  </div>
</section>
