import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as AOS from 'aos';

@Component({
  selector: 'NavBarHome',
  templateUrl: './nav-bar-home.component.html',
  styleUrls: ['./nav-bar-home.component.scss'],
})
export class NavBarHomeComponent implements OnInit {
  isMobile = false;
  environment = environment
  constructor(private router: Router) {}

  ngOnInit(): void {
    AOS.init();
  }

  toggleMobileNavbar(): void {
    if (this.isMobile) this.hideNavMenu();
    else this.showNavMenu();
  }

  hideNavMenu(): void {
    if (this.isMobile) {
      const navbar: Element = document.querySelector('#navbar') as Element;
      const toggle: Element = document.querySelector(
        '.mobile-nav-toggle'
      ) as Element;
      navbar.classList.toggle('navbar-mobile');
      navbar.classList.toggle('navbar');
      toggle.classList.toggle('ri-menu-line');
      toggle.classList.toggle('ri-close-line');
    }
  }

  showNavMenu(): void {
    const navbar: Element = document.querySelector('#navbar') as Element;
    const toggle: Element = document.querySelector(
      '.mobile-nav-toggle'
    ) as Element;
    navbar.classList.toggle('navbar-mobile');
    navbar.classList.toggle('navbar');
    toggle.classList.toggle('ri-menu-line');
    toggle.classList.toggle('ri-close-line');
    this.isMobile = true;
  }

  isNoAnchor(): boolean {
    return this.isAnchor(undefined);
  }

  isNoRoute(): boolean {
    return this.isRoute(undefined)
  }

  isHomeAnchor(): boolean {
    return this.isAnchor('home');
  }

  isServicesAnchor(): boolean {
    return this.isAnchor('services');
  }

  isPricingAnchor(): boolean {
    return this.isAnchor('pricing');
  }

  isContactAnchor(): boolean {
    return this.isRoute('contact');
  }

  isAboutAnchor(): boolean {
    return this.isRoute('a-propos');
  }

  isFeatureRoute(): boolean {
    return this.isRoute('fonctionnalites');
  }

  isEnrollmentRoute(): boolean {
    return this.isRoute('enrollment');
  }

  isAnchor(anchorValue: string | undefined): boolean {
    const anchor: string | undefined = this.getAnchor();
    const isPresent = anchor?.includes(anchorValue as string) as boolean
    return isPresent;
  }

  getAnchor(): string | undefined {
    const url: string = this.router.url;
    const anchor: string | undefined = url.split('#')[1];
    return anchor;
  }

  isRoute(routeValue: string | undefined): boolean {
    const route = this.getRoute();
    const isPresent = route?.includes(routeValue as string) as boolean
    return isPresent;
  }

  getRoute(): string | undefined {
    const url: string = this.router.url;
    const route = url.split("/")[1];
    return route
  }
}
