import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NewsletterService } from '../services/newsletter.service';
import { ToasterService } from '../services/toaster.service';
import { ValidatorsService } from '../services/validators.service';

@Component({
  selector: 'NewsletterForm',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.scss']
})
export class NewsletterFormComponent implements OnInit {

  mailInput = new FormControl("", [Validators.required, Validators.email]);


  constructor(
    private service: NewsletterService,
    public validatorsService: ValidatorsService,
    private toasterService: ToasterService
  ) { }

  ngOnInit(): void {
  }

  subscribeNewsletter() {
    console.log(this.mailInput);
    if (this.mailInput.invalid) return;
    const mail = this.mailInput.value as string;
    this.service.subscribe(mail).subscribe({
      next: () => {
        this.mailInput.reset();
        this.toasterService.showSuccess(
          'Votre inscription a bien été prise en compte. Merci !'
        );
      },
      error: (err: any) => {
        this.toasterService.showError(err.message);
      },
    });
  }

}
