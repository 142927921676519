

<section id="gpp" class="d-flex align-items-center justify-content-center">
  <div class="container">
    <div class="card shadow border-white p-2 bg-secondary text-white">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <div class="section-title">
              <h2>Opportunité</h2>
              <p class="text-white">La garantie première page</p>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="gpp-item">
                  <div class="item-title fs-4">
                    <i class="bi bi-check2-all text-primary"></i>
                    Qu'est ce que c'est ?
                  </div>
                  <div class="item-detail">
                    <p>
                      La garantie première page est un avantage pour les membres
                      <strong>premium</strong>. Il permet d'augmenter les
                      chances de voir votre entreprise se placer dans la
                      première page de résultats.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="gpp-item">
                  <div class="item-title fs-4">
                    <i class="bi bi-check2-all text-primary"></i>
                    Equité garantie entre les membres
                  </div>
                  <div class="item-detail">
                    <p>
                      Etant donné qu'il n'y a que 10 places dans la première
                      page, si plus de 10 candidats prétendent à la recherche,
                      les 10 entreprises seront choisies aléatoirement parmis
                      tous les candidats premium répondant aux critères de
                      recherche.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="fs-5">
              <a class="btn btn-outline-primary" routerLink="/inscription"
                >J'en profite
                <i class="bi bi-chevron-double-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>