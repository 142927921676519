import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { HomePageComponent } from './home-page/home-page.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { HomeJumbotronComponent } from './home-page/home-jumbotron/home-jumbotron.component';
import { WhyUsCardComponent } from './home-page/why-us-card/why-us-card.component';
import { ServicesComponent } from './home-page/services/services.component';
import { PricingComponent } from './home-page/pricing/pricing.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { FeaturesPageComponent } from './features-page/features-page.component';
import { FeatureJumbotronComponent } from './features-page/feature-jumbotron/feature-jumbotron.component';
import { CurvedCtaFeaturesComponent } from './features-page/curved-cta/curved-cta.component';
import { FeatureSearchComponent } from './features-page/feature-search/feature-search.component';
import { FeatureGppComponent } from './features-page/feature-gpp/feature-gpp.component';
import { FeatureEspaceComponent } from './features-page/feature-espace/feature-espace.component';
import { FeatureComingComponent } from './features-page/feature-coming/feature-coming.component';
import { FeatureArticleComponent } from './features-page/feature-article/feature-article.component';
import { AboutPageComponent } from './about-page/about-page.component';
import { AboutJumbotronComponent } from './about-page/about-jumbotron/about-jumbotron.component';
import { CurvedCtaAboutComponent } from './about-page/curved-cta/curved-cta.component';
import { AboutCompanyComponent } from './about-page/about-company/about-company.component';
import { AboutProudComponent } from './about-page/about-proud/about-proud.component';
import { AboutTeamComponent } from './about-page/about-team/about-team.component';
import { AboutCtaComponent } from './about-page/about-cta/about-cta.component';
import { AboutHistoryComponent } from './about-page/about-history/about-history.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    HomeJumbotronComponent,
    WhyUsCardComponent,
    ServicesComponent,
    PricingComponent,
    ContactPageComponent,
    FeaturesPageComponent,
    FeatureJumbotronComponent,
    CurvedCtaFeaturesComponent,
    FeatureSearchComponent,
    FeatureGppComponent,
    FeatureEspaceComponent,
    FeatureComingComponent,
    FeatureArticleComponent,
    AboutPageComponent,
    AboutJumbotronComponent,
    CurvedCtaAboutComponent,
    AboutCompanyComponent,
    AboutProudComponent,
    AboutTeamComponent,
    AboutCtaComponent,
    AboutHistoryComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    NgxUsefulSwiperModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
