<NavBarHome></NavBarHome>
<main>
	<FeaturesJumbotron></FeaturesJumbotron>
	<FeatureSearch></FeatureSearch>
	<FeatureGpp></FeatureGpp>
	<FeatureEspace></FeatureEspace>
	<FeatureComing></FeatureComing>
	<FeatureArticle></FeatureArticle>
	<section>
		<div class="container">
		  <div class="card shadow border-white p-2">
			<div class="card-body">
			  <div class="row">
				<div class="col-sm-12">
				  <div class="section-title">
					<h2>A vous de jouer</h2>
					<p class="fs-4">
					  Rejoingnez l'aventure
					</p>
				  </div>
				  <div class="fs-5">
					<p>
					 Notre entreprise n’a plus aucun secret pour vous à présent. 
					 Il ne tient qu’à vous de sauter le pas et transformer votre façon d’acquérir de nouveaux clients
					</p>
				  </div>
				  <div class="row">
					<div class="col-12">
					  <a class="btn btn-primary" routerLink="/inscription"
						>Rejoindre l'aventure <i class="bi bi-chevron-double-right"></i>
					  </a>
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  </section>
	  
	  <NewsletterModal #newsletter></NewsletterModal>
	  
</main>
<Footer></Footer>

<a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

