import { AbstractControl, FormArray, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidatorsService {
  constructor() {}

  phoneNumberValidator(ctl: AbstractControl): ValidationErrors | null {
    if (!ctl) return null;
    if (!ctl.value) return { required: true };
    const regex: RegExp = new RegExp(/\+33[6-7][0-9]{8}/);
    const tel: string = ctl.value;
    const valid: boolean = tel.match(regex) === null;
    if (valid) return { wrongTelFormat: valid };
    return null;
  }

  categoriesValidator(ctl: AbstractControl): ValidationErrors | null {
    if (!ctl) return null;
    const fa: FormArray = ctl as FormArray
    if(fa.length < 1) return { required: true};
    return null;
  }

  fakeInputValidator(ctl: AbstractControl): ValidationErrors | null {
    if (!ctl) return null;
    if(!ctl.value) return null;
    return {fake: false}
  }

  matchPasswordsValidator(ctl: AbstractControl): ValidationErrors | null {
    const pwd = ctl.get('password');
    const confirm = ctl.get('confirm');

    if (pwd && confirm && pwd.value !== confirm.value) {
      return { matchPasswords: true };
    }
    return null;
  }

  // Error getters

  hasRequiredError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('required');
    return false;
  }

  hasMailError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('email');
    return false;
  }

  hasWrongNumberFormatError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('wrongTelFormat');
    return false;
  }

  hasRequiredErrorKindsFA(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.hasError('required');
    return false
  }

  hasSirenError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('sirenNotExist');
    return false;
  }

  hasSiretError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('siretNotExist');
    return false;
  }

  hasTooMuchKeywordsError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('tooMuchKeywords');
    return false;
  }

  hasMaxLengthError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('maxlength');
    return false;
  }

  hasFormatError(ctl: AbstractControl | null): boolean {
    if (ctl) return ctl.touched && ctl.hasError('formatError');
    return false;
  }
}
