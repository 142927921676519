<form>
	<div class="input-group mb-3">
		<input type="email" class="form-control" [formControl]="mailInput" placeholder="Adresse mail" aria-label="Adresse mail" aria-describedby="button-addon2">
		<button class="btn btn-primary" type="button" id="button-addon2" (click)="subscribeNewsletter()">Je m'abonne</button>
	</div>
	<div *ngIf="mailInput.invalid"  class="text-danger">
		<span *ngIf="validatorsService.hasRequiredError(mailInput)">Ce champ est obligatoire.</span>
		<span *ngIf="validatorsService.hasMailError(mailInput)">Le format est incorrect.</span>
	</div>
</form>
<p class="fst-italic">
	La plateforme Mon Courtier & Moi s'engage à ne pas surcharger votre boite mail ni à revendre vos données.
</p>
