<footer id="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <div class="footer-info">
            <h3>MC&M<span>.</span></h3>
            <div class="social-links mt-3">
              <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
              <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
              <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
              <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-12 footer-links">
          <h4>Liens utiles</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/">Accueil</a></li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a routerLink="/a-propos">A propos</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a routerLink="/fonctionnalites">Fonctionnalités</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a routerLink="/garantie-premiere-page">Garantie première page</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a routerLink="/contact">Contact</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a routerLink="/cgu">Conditions générales</a>
            </li>
          </ul>
        </div>

        <div class="col-md-4 col-sm-12 footer-newsletter">
          <h4>Rejoignez notre newsletter</h4>
          <NewsletterForm></NewsletterForm>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="copyright">
      &copy; Copyright <strong><span>MonCourtier&Moi</span></strong
      >. All Rights Reserved<br />
      Designed by
      <a href="https://rubenguenoun.com" target="_blank">Ruben Guenoun</a>
    </div>
  </div>
</footer>
