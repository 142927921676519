<header id="header" class="fixed-top bg-secondary">
  <div class="container d-flex align-items-center justify-content-lg-between">
    <h1 class="logo me-auto me-lg-0">
      <a routerLink="/">MC&M<span>.pro</span></a>
    </h1>
    <!-- Uncomment below if you prefer to use an image logo -->
    <!-- <a href="index.html" class="logo me-auto me-lg-0"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

    <nav id="navbar" class="navbar order-last order-lg-0 rounded">
      <ul>
        <li class="dropdown">
          <a
            class="nav-link scrollto "
            [class.active]="isHomeAnchor() || isServicesAnchor() || isPricingAnchor()"
            (click)="hideNavMenu()"
            href="#home"
            >Accueil
            <i class="bi bi-chevron-down"></i>
          </a>
          <ul>
            <li><a href="#services">Services</a></li>
            <li><a href="#pricing">Tarifs</a></li>
          </ul>
        </li>
        <li class="dropdown">
          <a
            class="nav-link scrollto"
            [class.active]="isAboutAnchor()"
            (click)="hideNavMenu()"
            routerLink="/a-propos"
            >A propos
            <i class="bi bi-chevron-down"></i>
          </a>
          <ul>
            <li><a [routerLink]="['/a-propos']" fragment="about">Le projet</a></li>
            <li><a [routerLink]="['/a-propos']" fragment="team">Notre équipe</a></li>
            <li><a [routerLink]="['/a-propos']" fragment="origine">Notre histoire</a></li>
          </ul>
        </li>
        <li class="dropdown">
          <a
            class="nav-link scrollto"
            [class.active]="isFeatureRoute()"
            (click)="hideNavMenu()"
            routerLink="/fonctionnalites"
            >Fonctionnalités <i class="bi bi-chevron-down"></i>
            </a>
          <ul>
            <li><a [routerLink]="['/fonctionnalites']" fragment="search">Le moteur de recherche</a></li>
            <li><a [routerLink]="['/fonctionnalites']" fragment="gpp">Garantie première page</a></li>
            <li><a [routerLink]="['/fonctionnalites']" fragment="espace">Votre espace courtier</a></li>
            <li><a [routerLink]="['/fonctionnalites']" fragment="articles">L'espace article</a></li>
          </ul>
        </li>
        <li>
          <a
            class="nav-link scrollto"
            [class.active]="isContactAnchor()"
            (click)="hideNavMenu()"
            routerLink="/contact"
            >Contact</a
          >
        </li>
      </ul>
      <i
        class="ri-menu-line mobile-nav-toggle"
        (click)="toggleMobileNavbar()"
      ></i>
    </nav>
    <!-- .navbar -->

    <div class="btn-navs">
      <a [href]="environment.BO_URL" target="blank" class="get-started-btn scrollto">Espace pro</a>
      <a routerLink="/inscription" class="get-started-btn btn btn-primary">M'inscrire</a>
    </div>

  </div>
</header>
