<section
  id="home"
  class="d-flex align-items-center mt-5"
>
  <div class="container d-flex align-items-center justify-content-center flex-column">
    <div class="row justify-content-md-center">
      <div
        id="text-col"
        class="col-sm-12 text-center d-flex flex-column justify-content-center"
      >
        <h1>Qui sommes-nous ?</h1>
        <h2>
          Découvrez qui nous sommes et nous vous aiderons à atteindre vos objectifs. 
        </h2>
      </div>
    </div>
    <div class="row mt-2 align-items-center justify-content-center">
      <div class="col-lg-6 col-sm-8 d-flex align-items-center gap-2 justify-content-center">
        <a [routerLink]="['/a-propos']" fragment="about" class="text-center fs-1">
          <i class="bi bi-chevron-double-down"></i>
        </a>
      </div>
    </div>
  </div>
</section>
