<NavBarHome></NavBarHome>
<main>
  <AboutJumbotron></AboutJumbotron>
  <!-- <CurvedCtaAbout></CurvedCtaAbout> -->
  <AboutCompany></AboutCompany>
  <AboutProud></AboutProud>

  <section class="bg-light">
    <div class="container">
      <div class="card shadow border-white p-2 bg-secondary text-white">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <div class="section-title">
                <h2>Opportunité</h2>
                <p class="text-white">
                  Renouvelez votre stratégie d’acquisition
                </p>
              </div>
              <div class="fs-5">
                <p>
                  Démarrez l'aventure et changez votre façon d'obtenir de
                  nouveux prospects.
                </p>
                <a class="btn btn-outline-primary" routerLink="/inscription"
                  >Démarrez maintenant
                  <i class="bi bi-chevron-double-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <AboutTeam></AboutTeam>
  <AboutHistory></AboutHistory>

  <section class="bg-light">
    <div class="container">
      <div class="card shadow border-white p-2">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <div class="section-title">
                <h2>A vous de jouer</h2>
                <p>
                  Rejoignez l'aventure
                </p>
              </div>
              <div class="fs-5">
                <p>
                  Notre entreprise n’a plus aucun secret pour vous à présent. Il ne tient qu’à vous de sauter le pas et transformer votre façon d’acquérir de nouveaux clients
                </p>
                <a class="btn btn-primary" routerLink="/inscription"
                  >Rejoindre
                  <i class="bi bi-chevron-double-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<Footer></Footer>
