<NavBarHome></NavBarHome>

<main>
  <HomeJumbotron></HomeJumbotron>

  <section id="cta-fr">
    <div class="container">
      <div class="card shadow border-white p-2">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="section-title">
                <h2>Souveraineté</h2>
                <p>Un service 100% français</p>
              </div>
              <div class="fs-5">
                <p>
                  La plateforme est <strong>souveraine</strong> et hébergée en
                  <strong>France</strong> pour garantir la
                  <strong>protection de vos données</strong> et la
                  <strong>confidentialité</strong> de vos échanges avec les
                  professionnels.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="d-flex align-items-center justify-content-center">
                <img
                  id="home-img"
                  class="img-fluid"
                  src="/assets/img/france-flag.gif"
                  alt="Drapeau de la France"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <HomeServices></HomeServices>

  <Pricing></Pricing>

  <section class="bg-light">
    <div class="container">
      <div class="card shadow border-white p-2">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <div class="section-title">
                <h2>Opportunité</h2>
                <p>Profitez de la garantie première page</p>
              </div>
              <div class="fs-5">
                <p>
                  Vous pouvez décider d'améliorer vos chances d'apparaitre sur la
                  première page de résultat pour les recherches qui correspondent à
                  votre secteur.
                </p>
                <a
                class="btn btn-primary"
                [routerLink]="['/fonctionnalites']"
                [fragment]="'gpp'"
                >Je découvre <i class="bi bi-chevron-double-right"></i>
                </a
              >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <WhyUsCards></WhyUsCards>


  <section class="bg-light">
    <div class="container">
      <div class="card shadow border-white p-2 bg-secondary text-white">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <div class="section-title">
                <h2>A vous de jouer</h2>
                <p class="text-white">Profitez d’une nouvelle stratégie d’acquisition</p>
              </div>
              <div class="fs-5">
                <p>
                  Démarrez l'aventure et changez votre façon d'obtenir de nouveux prospects.
                </p>
                <a
                class="btn btn-outline-primary"
                routerLink="/inscription"
                >Démarrez maintenant <i class="bi bi-chevron-double-right"></i>
                </a
              >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer></Footer>
</main>
