import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'HexaCard',
  templateUrl: './hexa-card.component.html',
  styleUrls: ['./hexa-card.component.scss']
})
export class HexaCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
