<section id="whyus" class="bg-light">
  <div class="container">
    <div class="section-title">
      <h2>Pourquoi ?</h2>
      <p>Vous offrir le meilleur est notre priorité</p>
    </div>
  
    <div class="row">
      <div class="col-lg-6 col-md-12 d-flex align-items-center">
        <div class="icons">
          <div class="icon-item mb-2">
            <div class="icon-box bg-primary p-4">
              <i class="bi bi-shield-check fs-1"></i>
            </div>
            <div class="icon-text mb-2">
              <h4 class="fw-bolder">Sécurité</h4>
              <p class="text-muted fw-semibold">Vos données ne servirons à <span class="text-primary">aucune activité parallèle</span>.</p>
            </div>
          </div>
          <div class="icon-item">
            <div class="icon-box bg-secondary p-4">
              <i class="bi bi-check2-square text-white"></i>
            </div>
            <div class="icon-text">
              <h4 class="fw-bolder">Qualité</h4>
              <p class="text-muted fw-semibold">Nous garantissons <span class="text-primary">la qualité des leads</span> envoyés.</p>
            </div>
          </div>
          <div class="icon-item">
            <div class="icon-item">
              <div class="icon-box bg-info p-4">
                <i class="bi bi-emoji-sunglasses text-white"></i>
              </div>
              <div class="icon-text">
                <h4 class="fw-bolder">Tranquillité</h4>
                <p class="text-muted fw-semibold">Profitez d'une expérience <span class="text-primary">sans pubs ni spams</span>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <img src="/assets/img/why.svg" alt="Illustration: Pourquoi choisir Mon Courtier & Moi ?" class="img-fluid">
      </div>
    </div>
  </div>
</section>
