<section id="news">
  <div class="container">
    <div class="card shadow border-white p-2">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <div class="section-title">
              <h2>Prochainement</h2>
              <p class="fs-4">
                Accédez en avant première des nouvelles fonctionnalités
              </p>
            </div>
            <div class="fs-5">
              <p>
                En souscrivant à notre offre Premium, vous pourrez utiliser les
                nouvelles fonctionnalités sans surcoût.
              </p>
            </div>
            <div class="row">
              <div class="col-12">
                <a class="btn btn-primary" routerLink="/inscription"
                  >Je m'inscris <i class="bi bi-chevron-double-right"></i>
                </a>
                <button
                  class="btn btn-secondary ms-lg-2"
                  (click)="openNewsletterModal()"
                >
                  Je veux rester informé
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<NewsletterModal #newsletter></NewsletterModal>
