import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'HomeServices',
  templateUrl: './services.component.html',
  styleUrls: ['../../../assets/theme/scss/variables.scss', './services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
